// @flow
import React from 'react'
import { Typography, Box, Link as MuiLink } from '@material-ui/core'

import { useGet, useMatchSizes } from 'hooks'
import { Table, Link } from 'components'
import { constants } from 'config'

function ListClients() {
  const { data, loading, error } = useGet('client/all')
  const { xsDown, mdDown } = useMatchSizes()

  if (error) {
    return (
      <Box display='flex' justifyContent='center'>
        <Typography color='secondary' variant='body2'>
          {error}
        </Typography>
      </Box>
    )
  }

  return (
    <>
      <Typography align='center' variant='h6'>
        Lista de clientes
      </Typography>

      <Table
        name='clientes'
        isLoading={loading}
        data={data || []}
        actions={
          mdDown
            ? null
            : [
                (rowData: Object) => ({
                  icon: 'cloud_download',
                  tooltip: 'Solicitud de credito',
                  onClick: () =>
                    window.open(
                      `${constants.baseUrl}/docs/osocreditos/request/${rowData.id}`
                    )
                })
              ]
        }
        columns={[
          {
            title: 'Id',
            field: 'id',
            render: function Column(rowData: Object) {
              return (
                <Link
                  to={{
                    pathname: `/inicio/clientes/perfil/${rowData.id}`,
                    state: rowData
                  }}
                >
                  <Typography variant='body2' color='primary'>
                    {rowData.id}
                  </Typography>
                </Link>
              )
            }
          },
          {
            title: 'Nombre',
            field: 'name'
          },
          {
            title: 'Cédula',
            field: 'idNumber',
            hidden: xsDown
          },
          {
            title: 'Dirección',
            field: 'address',
            hidden: xsDown
          },
          {
            title: 'Teléfono',
            field: 'phoneNumber',
            disableClick: true,
            render: function PhoneNumberRow(rowData: Object) {
              return (
                <MuiLink
                  target='_blank'
                  rel='noreferrer'
                  href={`https://wa.me/506${rowData.phoneNumber}`}
                >
                  <Typography variant='body2' color='primary'>
                    {rowData.phoneNumber}
                  </Typography>
                </MuiLink>
              )
            }
          },
          {
            title: 'Correo',
            field: 'email',
            hidden: true
          },
          {
            title: 'Descripción',
            field: 'description',
            hidden: true
          },
          {
            title: 'Lugar de trabajo',
            field: 'workplace',
            hidden: true
          }
        ]}
      />
    </>
  )
}

export default ListClients
