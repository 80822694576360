// @flow
import React from 'react'
import { Switch } from 'react-router-dom'

import Add from './Add'
import List from './List'
import Details from './Details'
import Pay from './Pay'
import NoMatch from 'screens/NoMatch'
import { CustomRoute } from 'components'

function LoansRouter() {
  return (
    <Switch>
      <CustomRoute
        exact
        path='/inicio/prestamos/agregar'
        component={Add}
        isPrivate
      />
      <CustomRoute
        exact
        path='/inicio/prestamos/mostrar'
        component={List}
        isPrivate
      />
      <CustomRoute
        exact
        path='/inicio/prestamos/detalle/:id'
        component={Details}
        isPrivate
      />
      <CustomRoute
        exact
        path='/inicio/prestamos/pagar/:id'
        component={Pay}
        isPrivate
      />
      <CustomRoute component={NoMatch} />
    </Switch>
  )
}

export default LoansRouter
