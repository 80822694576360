import React, { useState, useEffect } from 'react'
import {
  Box,
  Grid,
  Typography,
  MenuItem,
  FormControlLabel,
  Switch,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'
import { Formik, Form, Field } from 'formik'
import { useLocation, useParams, useHistory } from 'react-router-dom'

import {
  TextField,
  LoadingButton,
  Button,
  AmountField,
  Snackbar
} from 'components'
import { getFee, formatAmount, formatDate, auth } from 'utils'
import { constants } from 'config'
import { loanScheme } from 'schemas'
import { useGet, usePost } from 'hooks'

const { initialValues, schema } = loanScheme.pay

function Pay() {
  const [data, setData] = useState(null)
  const { state, pathname } = useLocation()
  const { id } = useParams()
  const history = useHistory()
  const [openDialog, setOpenDialog] = useState(false)
  const [paymentData, setPaymentData] = useState(null)
  const { loading: loanLoading, error: loanError, data: loanData } = useGet(
    state ? null : `loan/${id}`
  )
  const {
    post,
    isError: postIsError,
    loading: postLoading,
    error: postError
  } = usePost()

  useEffect(() => {
    if (!state) {
      return
    }

    const stateCopy = { ...state }

    if (stateCopy && stateCopy.tableData) {
      delete stateCopy.tableData
    }

    setData(stateCopy)
  }, [state])

  useEffect(() => {
    if (!loanData) {
      return
    }

    history.replace({ pathname, state: loanData })
  }, [loanData, history, pathname])

  const handleCloseDialog = () => {
    setOpenDialog(false)
    setPaymentData(null)
  }

  const handleShowReceipt = () => {
    window.open(
      `${constants.baseUrl}/docs/osocreditos/paymentReceipt/${paymentData?.client?.id}/${paymentData?.payment?.id}`
    )

    setOpenDialog(false)
    setPaymentData(null)
  }

  if (
    (loanLoading || (!loanData && !data) || (loanData && !data)) &&
    !loanError
  ) {
    return (
      <Card>
        <CardHeader title={<Skeleton height={50} width='100%' />} />
        <CardContent>
          <Skeleton height={50} width='100%' />
          <Skeleton height={50} width='100%' />
          <Skeleton height={50} width='100%' />
        </CardContent>
      </Card>
    )
  }

  if (loanError) {
    return (
      <Box display='flex' justifyContent='center'>
        <Typography color='secondary' variant='body2'>
          {loanError}
        </Typography>
      </Box>
    )
  }

  if (!data) {
    return (
      <Card>
        <CardHeader title={`No existe un préstamo con el id ${id}`} />
      </Card>
    )
  }

  const { expectedAmount, periodicityFee, interestAmount } = getFee(
    data.loanType.type === constants.FIXED ? data.balance : data.amount,
    data.interestPercentage,
    data.capitalPercentage,
    data.loanPeriodicity.times,
    data.penaltyFee
  )

  const options = () => {
    const response = []
    if (expectedAmount !== periodicityFee) {
      response.push({
        id: 1,
        label: 'Cuota completa',
        value: expectedAmount,
        formattedValue: formatAmount(expectedAmount)
      })
    }

    response.push({
      id: 2,
      label: 'Cuota Fija',
      value: periodicityFee,
      formattedValue: formatAmount(periodicityFee)
    })

    if (interestAmount !== periodicityFee) {
      response.push({
        id: 3,
        label: 'Solo intereses',
        value: interestAmount,
        formattedValue: formatAmount(interestAmount)
      })
    }

    return response
  }

  const details = [
    { id: 1, label: 'Nombre:', value: data.client.name },
    { id: 2, label: 'Monto:', value: formatAmount(data.amount) },
    {
      id: 3,
      label: 'Balance:',
      value: formatAmount(data.balance)
    },
    {
      id: 4,
      label: 'Multas o deudas:',
      value: formatAmount(data.penaltyFee)
    },
    {
      id: 5,
      label: 'Periodicidad:',
      value: data.loanPeriodicity.name
    },
    { id: 6, label: 'Tipo:', value: data.loanType.name },
    {
      id: 7,
      label: 'Fecha de pago:',
      value: formatDate(data.payday)
    }
  ]

  return (
    <Box
      display='flex'
      flexDirection='column'
      justifyContent='center'
      alignItems='center'
    >
      <Box mb={3}>
        <Typography variant='h6'>Pagar préstamo</Typography>
      </Box>

      <Snackbar
        isVisible={Boolean(postIsError && postError)}
        type='error'
        message={
          typeof postError === 'string'
            ? postError
            : postError && postError.message
        }
      />

      <Dialog onClose={handleCloseDialog} open={openDialog}>
        <DialogTitle id='simple-dialog-title'>Exito!</DialogTitle>
        <DialogContent>{`El pago a nombre de ${
          paymentData?.client?.name
        } por un monto de ${formatAmount(
          paymentData?.payment?.amount
        )} ha sido registrado exitosamente`}</DialogContent>
        <DialogActions>
          <Button onClick={handleShowReceipt} color='primary'>
            Ver recibo
          </Button>
          <Button onClick={handleCloseDialog} color='primary'>
            Continuar
          </Button>
        </DialogActions>
      </Dialog>

      <Grid container justify='center'>
        <Grid item sm={4} xs={12}>
          <Box mb={3}>
            <Card>
              <CardContent>
                <Grid container justify='center' spacing={1}>
                  {details.map((detail: Object) => (
                    <Grid item xs={12} key={detail.id}>
                      <Box display='flex' flexDirection='row'>
                        <Typography variant='body2'>
                          {detail.label} <br />
                        </Typography>
                        <Typography variant='body2' color='textSecondary'>
                          {detail.value}
                        </Typography>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </CardContent>
            </Card>
          </Box>
        </Grid>
      </Grid>

      <Grid container justify='center'>
        <Grid item sm={6} xs={12}>
          <Formik
            initialValues={initialValues}
            validationSchema={schema}
            onSubmit={(
              values: Object,
              { resetForm }: { resetForm: Function }
            ) => {
              const body = {
                ...values,
                loandId: data.id,
                userId: auth.decodedToken().id
              }

              post('loan/pay', body, (loan: Object) => {
                setOpenDialog(true)
                setPaymentData(loan)
                history.replace({ pathname, state: loan })
                resetForm(initialValues)
              })
            }}
          >
            {({
              errors,
              touched,
              setFieldValue,
              values
            }: {
              errors: Object,
              touched: Object,
              setFieldValue: Function,
              values: Object
            }) => {
              return (
                <Form>
                  <Box display='flex' justifyContent='space-between'>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={values.isTypeField}
                          onChange={() => {
                            setFieldValue('isTypeField', !values.isTypeField)
                            setFieldValue('amount', '')
                            setFieldValue('isTypeToCapital', false)
                          }}
                          value='isTypeField'
                          color='primary'
                        />
                      }
                      label={values.isTypeField ? 'Seleccionar' : 'Digitar'}
                    />
                    <Field name='isTypeToCapital'>
                      {({ field }: { field: Object }) => {
                        return (
                          <FormControlLabel
                            control={
                              <Switch
                                checked={field.value}
                                onChange={(ev: Object) => {
                                  setFieldValue('isTypeField', true)
                                  setFieldValue('amount', '')
                                  setFieldValue(field.name, ev.target.checked)
                                }}
                                value={field.value}
                                color='primary'
                              />
                            }
                            label={field.value ? 'Al Periodo' : 'Al Capital'}
                          />
                        )
                      }}
                    </Field>
                  </Box>
                  <Field name='amount'>
                    {({ field }: { field: Object }) => {
                      if (values.isTypeField) {
                        return (
                          <AmountField
                            name={field.name}
                            label='Monto*'
                            value={field.value}
                            onValueChange={(values: Object) => {
                              setFieldValue(field.name, values.value)
                            }}
                            error={Boolean(touched.amount && errors.amount)}
                            helperText={touched.amount && errors.amount}
                          />
                        )
                      }

                      return (
                        <TextField
                          select
                          {...field}
                          label='Monto*'
                          error={Boolean(touched.amount && errors.amount)}
                          helperText={touched.amount && errors.amount}
                        >
                          {options().map(
                            ({
                              id,
                              label,
                              value,
                              formattedValue
                            }: {
                              id: number,
                              label: string,
                              value: number,
                              formattedValue: string
                            }) => (
                              <MenuItem key={id} value={value}>
                                {`${label} - ${formattedValue}`}
                              </MenuItem>
                            )
                          )}
                        </TextField>
                      )
                    }}
                  </Field>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={values.addNotesField}
                        onChange={() => {
                          setFieldValue('addNotesField', !values.addNotesField)
                          setFieldValue('notes', '')
                        }}
                        value='addNotesField'
                        color='primary'
                      />
                    }
                    label={
                      values.addNotesField ? 'Quitar nota' : 'Agregar nota'
                    }
                  />
                  <Field name='notes'>
                    {({ field }: { field: Object }) => {
                      if (values.addNotesField) {
                        return (
                          <TextField
                            {...field}
                            label='Nota'
                            error={Boolean(touched.notes && errors.notes)}
                            helperText={touched.notes && errors.notes}
                          />
                        )
                      }
                      return null
                    }}
                  </Field>
                  <Box mt={3}>
                    <LoadingButton loading={postLoading}>
                      <Button disabled={postLoading}>Pagar</Button>
                    </LoadingButton>
                  </Box>
                </Form>
              )
            }}
          </Formik>
        </Grid>
      </Grid>
    </Box>
  )
}

export default Pay
