// @flow
import { auth } from 'utils'

const options = (userId: number) => [
  {
    id: 1,
    key: 'clients',
    displayName: 'Clientes',
    path: '/inicio/clientes',
    iconName: 'people',
    isProtected: false,
    subMenu: [
      {
        id: 1,
        displayName: 'Agregar',
        path: '/inicio/clientes/agregar',
        iconName: 'add',
        isProtected: false
      },
      {
        id: 2,
        displayName: 'Mostrar',
        path: '/inicio/clientes/mostrar',
        iconName: 'format_list_bulleted',
        isProtected: true
      }
    ]
  },
  {
    id: 2,
    key: 'loans',
    displayName: 'Préstamos',
    path: '/inicio/prestamos',
    iconName: 'description',
    isProtected: false,
    subMenu: [
      {
        id: 1,
        displayName: 'Agregar',
        path: '/inicio/prestamos/agregar',
        iconName: 'add',
        isProtected: false
      },
      {
        id: 2,
        displayName: 'Mostrar',
        path: '/inicio/prestamos/mostrar',
        iconName: 'format_list_bulleted',
        isProtected: true
      },
      {
        id: 3,
        displayName: 'Personales',
        path: `/inicio/prestamos/mostrar?userId=${userId}`,
        iconName: 'person',
        isProtected: false
      }
    ]
  },
  {
    id: 3,
    key: 'payments',
    displayName: 'Pagos',
    path: '/inicio/pagos',
    iconName: 'work_outline',
    isProtected: false,
    subMenu: [
      {
        id: 1,
        displayName: 'Mostrar',
        path: '/inicio/pagos/mostrar',
        iconName: 'format_list_bulleted',
        isProtected: true
      },
      {
        id: 2,
        displayName: 'Hoy',
        path: `/inicio/pagos/mostrar/hoy`,
        iconName: 'filter_list',
        isProtected: true
      },
      {
        id: 3,
        displayName: 'Personales',
        path: `/inicio/pagos/mostrar/personales`,
        iconName: 'person',
        isProtected: false
      }
    ]
  },
  {
    id: 4,
    key: 'users',
    displayName: 'Usuarios',
    path: '/inicio/usuarios',
    iconName: 'supervisor_account',
    isProtected: true,
    subMenu: [
      {
        id: 1,
        displayName: 'Agregar',
        path: '/inicio/usuarios/agregar',
        iconName: 'add'
      },
      {
        id: 2,
        displayName: 'Mostrar',
        path: '/inicio/usuarios/mostrar',
        iconName: 'format_list_bulleted'
      }
    ]
  },
  {
    id: 5,
    key: 'profile',
    displayName: 'Perfil',
    path: '/inicio/perfil',
    iconName: 'person',
    isProtected: false
  },
  {
    id: 6,
    key: 'signOut',
    displayName: 'Cerrar Sesión',
    path: '/',
    iconName: 'exit_to_app',
    isProtected: false
  }
]

const getDrawer = (userId: number) => {
  const isAdminOrSupervisor = auth.isAdminOrSupervisor()
  if (isAdminOrSupervisor) {
    const initialState = {}
    options(userId).map(
      ({ key }: { key: string }) => (initialState[key] = false)
    )

    return {
      options: options(userId),
      initialState
    }
  }

  const initialState = {}
  const filteredOptions = options(userId)
    .filter((option: Object) => !option.isProtected)
    .map((option: Object) => {
      initialState[option.key] = false
      if (option.subMenu) {
        const subMenu = option.subMenu.filter(
          (subMenu: Object) => !subMenu.isProtected
        )

        return {
          ...option,
          subMenu
        }
      }

      return option
    })

  return {
    options: filteredOptions,
    initialState
  }
}

export default {
  getDrawer
}
