// @flow
import React, { useState } from 'react'
import {
  Grid,
  Typography,
  CircularProgress,
  Box,
  Button
} from '@material-ui/core'
import { DatePicker } from '@material-ui/pickers'
import moment from 'moment'
import { MTableToolbar } from 'material-table'

import { useGet, useMatchSizes } from 'hooks'
import { Table, LoadingButton, Link, Paper } from 'components'
import { formatAmount, formatDate, auth } from 'utils'
import { constants } from 'config'

function PersonalPayments() {
  const [filterValues, setFilterValues] = useState({
    from: moment().format(moment.defaultFormat),
    to: moment().format(moment.defaultFormat),
    userId: 'all'
  })

  const { id } = auth.decodedToken()

  const { data, loading, error, setUrl } = useGet(`payment/createdToday/${id}`)

  const handleFilter = () => {
    const url = `payment/filter/${id}/${filterValues.from}/${filterValues.to}`
    setUrl(url)
  }

  const { xsDown } = useMatchSizes()

  if (error) {
    return (
      <Box display='flex' justifyContent='center'>
        <Typography color='secondary' variant='body2'>
          {error}
        </Typography>
      </Box>
    )
  }

  return (
    <Box
      display='flex'
      flexDirection='column'
      justifyContent='center'
      alignItems='center'
    >
      <Typography variant='h6'>Lista de Pagos</Typography>
      <Grid container justify='center' alignItems='center' spacing={2}>
        <Grid item sm={3} xs={6}>
          <DatePicker
            margin='normal'
            inputVariant='outlined'
            fullWidth
            label='Desde'
            format='DD/MM/YYYY'
            value={filterValues.from}
            onChange={(from: Date) =>
              setFilterValues((values: Object) => ({
                ...values,
                from: from.format(moment.defaultFormat)
              }))
            }
          />
        </Grid>
        <Grid item sm={3} xs={6}>
          <DatePicker
            margin='normal'
            inputVariant='outlined'
            fullWidth
            label='Hasta'
            format='DD/MM/YYYY'
            value={filterValues.to}
            onChange={(to: Date) =>
              setFilterValues((values: Object) => ({
                ...values,
                to: to.format(moment.defaultFormat)
              }))
            }
          />
        </Grid>
        <Grid item sm={3} xs={12}>
          <LoadingButton loading={false}>
            <Button
              fullWidth
              variant='contained'
              color='primary'
              disabled={false}
              onClick={handleFilter}
            >
              Filtrar
            </Button>
          </LoadingButton>
        </Grid>

        <Grid item xs={12}>
          {loading && (
            <Box display='flex' justifyContent='center'>
              <CircularProgress />
            </Box>
          )}
          {!loading && data && data.payments && (
            <Table
              name='pagos'
              components={{
                Toolbar: function Toolbar(props: Object) {
                  const totalAmount =
                    (props &&
                      props.data &&
                      props.data.reduce(
                        (sum: number, item: Object) => sum + item.amount,
                        0
                      )) ||
                    0

                  const capitalAmount =
                    (props &&
                      props.data &&
                      props.data.reduce(
                        (sum: number, item: Object) => sum + item.capitalAmount,
                        0
                      )) ||
                    0

                  const interestAmount =
                    (props &&
                      props.data &&
                      props.data.reduce(
                        (sum: number, item: Object) =>
                          sum + item.interestAmount + item.penaltyAmount,
                        0
                      )) ||
                    0

                  return (
                    <>
                      <MTableToolbar {...props} />
                      <Box display='flex'>
                        <Paper>
                          <Typography variant='body2'>{`Total: ${formatAmount(
                            totalAmount
                          )}`}</Typography>
                        </Paper>

                        <Paper>
                          <Typography variant='body2'>{`Capital: ${formatAmount(
                            capitalAmount
                          )}`}</Typography>
                        </Paper>

                        <Paper>
                          <Typography variant='body2'>{`Interes: ${formatAmount(
                            interestAmount
                          )}`}</Typography>
                        </Paper>
                      </Box>
                    </>
                  )
                }
              }}
              columns={[
                {
                  title: 'Id',
                  field: 'id',
                  render: function Column(rowData: Object) {
                    return (
                      <Link
                        to={{
                          pathname: `/inicio/pagos/detalle/${rowData.id}`,
                          state: rowData
                        }}
                      >
                        <Typography variant='body2' color='primary'>
                          {rowData.id}
                        </Typography>
                      </Link>
                    )
                  }
                },
                {
                  title: 'Préstamo',
                  field: 'loan.id',
                  render: function Column(rowData: Object) {
                    return (
                      <Link
                        to={{
                          pathname: `/inicio/prestamos/detalle/${rowData.loan.id}`
                        }}
                      >
                        <Typography variant='body2' color='primary'>
                          {rowData.loan.id}
                        </Typography>
                      </Link>
                    )
                  }
                },
                {
                  title: 'Cliente',
                  field: 'client.name',
                  render: function ClientNameRow(rowData: Object) {
                    return (
                      <Link to={`/inicio/clientes/perfil/${rowData.clientId}`}>
                        <Typography variant='body2' color='primary'>
                          {rowData.client.name}
                        </Typography>
                      </Link>
                    )
                  }
                },
                {
                  title: 'Cobrador',
                  field: 'user.name',
                  hidden: xsDown,
                  render: function UserNameRow(rowData: Object) {
                    return (
                      <Link to={`/inicio/usuarios/perfil/${rowData.userId}`}>
                        <Typography variant='body2' color='primary'>
                          {rowData.user.name}
                        </Typography>
                      </Link>
                    )
                  }
                },
                {
                  title: 'Tipo',
                  field: 'loan.loanType.name',
                  hidden: xsDown
                },
                {
                  title: 'Interes %',
                  field: 'loan.interestPercentage',
                  customFilterAndSearch: (term: string, rowData: Object) => {
                    return (
                      rowData.loan.interestPercentage.toString() ===
                      term.toString()
                    )
                  },
                  hidden: xsDown,
                  render: function InterestPercentageRow(rowData: Object) {
                    return (
                      <Typography variant='body2'>
                        {`${rowData.loan.interestPercentage}%`}
                      </Typography>
                    )
                  }
                },
                {
                  title: 'Capital %',
                  field: 'loan.capitalPercentage',
                  customFilterAndSearch: (term: string, rowData: Object) => {
                    return (
                      rowData.loan.capitalPercentage.toString() ===
                      term.toString()
                    )
                  },
                  hidden: xsDown,
                  render: function CapitalPercentageRow(rowData: Object) {
                    return (
                      <Typography variant='body2'>
                        {`${rowData.loan.capitalPercentage}%`}
                      </Typography>
                    )
                  }
                },
                {
                  title: 'Periodicidad',
                  field: 'loan.loanPeriodicity.name',
                  hidden: xsDown
                },
                {
                  title: 'Interes',
                  field: 'interestAmount',
                  render: function InterestAmountRow(rowData: Object) {
                    return (
                      <Typography variant='body2'>
                        {formatAmount(rowData.interestAmount)}
                      </Typography>
                    )
                  }
                },
                {
                  title: 'Capital',
                  field: 'capitalAmount',
                  render: function CapitalAmountRow(rowData: Object) {
                    return (
                      <Typography variant='body2'>
                        {formatAmount(rowData.capitalAmount)}
                      </Typography>
                    )
                  }
                },
                {
                  title: 'Penalización',
                  field: 'penaltyAmount',
                  render: function PenaltyAmountRow(rowData: Object) {
                    return (
                      <Typography variant='body2'>
                        {formatAmount(rowData.penaltyAmount)}
                      </Typography>
                    )
                  }
                },
                {
                  title: 'Monto',
                  field: 'amount',
                  render: function AmountRow(rowData: Object) {
                    return (
                      <Typography variant='body2'>
                        {formatAmount(rowData.amount)}
                      </Typography>
                    )
                  }
                },
                {
                  title: 'Fecha',
                  field: 'createdAt',
                  render: function DateRow(rowData: Object) {
                    return (
                      <Typography variant='body2'>
                        {formatDate(rowData.createdAt)}
                      </Typography>
                    )
                  },
                  hidden: xsDown
                },
                {
                  title: 'Balance',
                  field: 'currentBalanceAmount',
                  render: function DateRow(rowData: Object) {
                    return (
                      <Typography variant='body2'>
                        {formatAmount(rowData.currentBalanceAmount)}
                      </Typography>
                    )
                  },
                  hidden: xsDown
                }
              ]}
              data={data.payments}
              actions={[
                {
                  icon: 'receipt',
                  tooltip: 'Ver recibo',
                  onClick: (event: Object, rowData: Object) => {
                    window.open(
                      `${constants.baseUrl}/docs/osocreditos/paymentReceipt/${rowData?.client?.id}/${rowData?.id}`
                    )
                  }
                }
              ]}
            />
          )}
        </Grid>
      </Grid>
    </Box>
  )
}

export default PersonalPayments
